<ng-container *ngIf="!iframeUrl">
    <div class="modal-header a_modal">
        <h4 class="modal-title" id="myModalLabel1">{{edit ? 'Edit Documents To Action' : 'New Documents To Action'}}
        </h4>
        <button type="button" (click)="modalRehide(false)" class="close" data-dismiss="modal" aria-label="Close"> <span
                aria-hidden="true">&times;</span> </button>
    </div>

    <div class="modal-body" style="padding: 15px 30px;">
        <form #f="ngForm" novalidate class="form" enctype="multipart/form-data" (ngSubmit)="onSubmit(f)">
            <div *ngIf="!edit" class="form-group">
                <label for="impersonatedUserId" class="c_n_fs">Docusign User*</label>
                <select  required 
                id="impersonatedUserId" 
                [(ngModel)]="fdsServiceAgreementAddModel.impersonatedUserId"
                #impersonatedUserId="ngModel" 
                name="impersonatedUserId" 
                class="form-control com_drop"  >
                    <option *ngFor="let users of DocUsersList" [value]="users.userId">
                        {{users.userName}}
                    </option>
                </select>
                <div class="form-group">
                    <div *ngIf="impersonatedUserId.invalid && (impersonatedUserId.dirty || impersonatedUserId.touched || f.submitted)" class="form_alert">
                        <span class="ft-alert-circle"></span>
                        <div *ngIf="impersonatedUserId.errors.required">
                            DocUsers is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="formGroupExampleInput">Title *</label>
                <input type="text" id="title" required maxlength=55 class="form-control" name="title"
                    [(ngModel)]="fdsServiceAgreementAddModel.title" #title="ngModel" placeholder="Title"
                    [disabled]="edit && fdsStatus">
                <div class="form-group">
                    <div *ngIf="title.invalid && (title.dirty || title.touched || f.submitted)" class="form_alert">
                        <span class="ft-alert-circle"></span>
                        <div *ngIf="title.errors.required">
                            Title is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="exampleFormControlTextarea1">Description* </label>
                <textarea id="description" required class="form-control" name="description"
                    [(ngModel)]="fdsServiceAgreementAddModel.description" #description="ngModel"
                    placeholder="Description" rows="3" [disabled]="edit && fdsStatus"></textarea>
                <div class="form-group">
                    <div *ngIf="description.invalid && (description.dirty || description.touched || f.submitted)"
                        class="form_alert">
                        <span class="ft-alert-circle"></span>
                        <div *ngIf="description.errors.required">
                            Description is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="formGroupExampleInput">Signature Required Before*</label>
                <div class="input-group date-picker">
                    <input required class="form-control border-right-0" placeholder="yyyy-mm-dd" name="expireDate"
                        id="expireDate" [(ngModel)]="fdsServiceAgreementAddModel.expireDate" ngbDatepicker
                        #expireDate="ngbDatepicker" readonly [minDate]="minDate">
                    <div class="input-group-append">
                        <button class="btn calendar border-left-0" (click)="expireDate.toggle()" type="button"
                            [disabled]="edit && fdsStatus"></button>
                        <!-- <button *ngIf="edit" class="btn calendar border-left-0" type="button"></button> -->
                    </div>
                </div>
                <div class="form-group">
                    <div *ngIf="expireDate.invalid && (expireDate.dirty || expireDate.touched || f.submitted)"
                        class="form_alert"> <!-- <div *ngIf="!expireDateRequired" class="form_alert"> -->
                        <span class="ft-alert-circle"></span>
                        <div *ngIf="expireDate.errors.required">
                            Date is required.
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-documents">
                <div class="row">
                    <div class="col-4">Client Adviser needs to sign?</div>
                    <ng-container *ngIf="fdsServiceAgreementAddModel.isAdvisor === 'true'">
                        <div class="col-4">Client Advisor</div>
                        <div class="col-4">Client Advisor's Email Address</div>
                    </ng-container>
                </div>

                <div class="row pt-1">
                    <div class="col-4">
                        <div class="form-group s-agreement">
                            <select class="form-control" name="isAdvisor" name="isAdvisor"
                                [disabled]="edit && fdsStatus" [(ngModel)]="fdsServiceAgreementAddModel.isAdvisor"
                                #isAdvisor="ngModel">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                    <ng-container *ngIf="fdsServiceAgreementAddModel.isAdvisor === 'true'">
                        <div class="col-4">
                            <div class="form-group">
                                <input type="text" id="advisor-name" maxlength=55 class="form-control"
                                    name="advisorName" [(ngModel)]="fdsServiceAgreementAddModel.advisorName"
                                    #advisorName="ngModel" placeholder="Advisor Name" (blur)="addAdvisorName()"
                                    [disabled]="edit && fdsStatus">
                                <div class="form-group">
                                    <div *ngIf="advisorName.invalid && (advisorName.dirty || advisorName.touched || f.submitted)"
                                        class="form_alert">
                                        <span class="ft-alert-circle"></span>
                                        <div *ngIf="advisorName.errors.required">
                                            Advisor Name is required.
                                        </div>
                                    </div>
                                    <div *ngIf="!fdsServiceAgreementAddModel.advisorName && fdsServiceAgreementAddModel.advisorEmail"
                                        class="form_alert">
                                        <span class="ft-alert-circle"></span>Advisor Name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <input type="email" id="advisor-email" class="form-control" name="advisorEmail"
                                    [(ngModel)]="fdsServiceAgreementAddModel.advisorEmail" #advisorEmail="ngModel"
                                    [disabled]="edit && fdsStatus" placeholder="Advisor Email"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" (blur)="addAdvisorEmail()">
                                <div class="form-group">
                                    <div *ngIf="(advisorEmail.dirty || advisorEmail.touched || f.submitted)"
                                        class="form_alert">
                                        <span *ngIf=" advisorEmail.invalid && fdsServiceAgreementAddModel.advisorEmail"
                                            class="ft-alert-circle"></span>

                                        <div *ngIf="fdsServiceAgreementAddModel.advisorEmail && advisorEmail.invalid">
                                            Advisor Email is invalid.
                                        </div>
                                    </div>
                                    <div *ngIf="!fdsServiceAgreementAddModel.advisorEmail && fdsServiceAgreementAddModel.advisorName"
                                        class="form_alert">
                                        <span class="ft-alert-circle"></span>Advisor Email is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="section-documents">
                <label class="titlex">Documents</label>
                <ng-container *ngIf="fdsServiceOldDocuments && fdsServiceOldDocuments.length>0">
                    <ng-container *ngFor="let serviceDocument of fdsServiceOldDocuments; let i = index">
                        <ul *ngIf="!serviceDocument.isArchived">
                            <li>
                                <div class="docs-div">
                                    <i class="fa fa-file" aria-hidden="true"></i>
                                    <span> {{serviceDocument.documentName}} </span>
                                    <div class="docs-div-close" (click)="deleteFile(i, serviceDocument, 1)"
                                        *ngIf="!isdocumentDelete"> <i class="fa fa-times-circle" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="serviceAgreementModel.fdsServiceDocuments.length>0">
                    <ng-container
                        *ngFor="let serviceDocument of serviceAgreementModel.fdsServiceDocuments; let i = index">
                        <ul>
                            <li>
                                <div class="docs-div">
                                    <i class="fa fa-file" aria-hidden="true"></i>
                                    <span> {{serviceDocument.documentName}} </span>
                                    <!-- <span> &nbsp;|&nbsp;  {{serviceDocument.templateName}} </span> -->
                                    <div class="docs-div-close" (click)="deleteFile(i, serviceDocument)"> <i
                                            class="fa fa-times-circle" aria-hidden="true"></i> </div>
                                </div>
                            </li>
                        </ul>
                    </ng-container>
                </ng-container>
            </div>


            <div class="section-documents-add">
                <fieldset class="scheduler-border">
                    <legend class="scheduler-border">Add Document</legend>
                    <div class="row">
                        <div class="col-sm bx">
                            <input name="document" accept="{{acceptDocType}}" (change)="onFileChange($event)"
                                type="file" [(ngModel)]="fdsServiceDocumentsAdd.document" #document="ngModel"
                                id="inputGroupFile01" class="form-control no-radious" style="padding: 7px 17px;">
                            <!-- <label class="custom-file-label" for="inputGroupFile01">{{documentLabel}}</label> -->
                            <div class="form-group">
                                <div *ngIf="!IsvalidDocument" class="form_alert">
                                    <span class="ft-alert-circle"></span>
                                    Please add at least one document.
                                </div>

                                <div *ngIf="!fileRequired" class="form_alert">
                                    <span class="ft-alert-circle"></span>
                                    Please choose file.
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-sm bx"> 
                            <select id="templateId" 
                                [(ngModel)]='fdsServiceDocumentsAdd.templateId' #templateId="ngModel" name="templateId" class="custom-select s-box no-radious" data-toggle="tooltip" data-trigger="hover" data-placement="top"
                                  data-title="Priority" data-original-title="" title="" placeholder="Please select" (change)="onTemplaChange()">
                                  <option value="000">Please select</option>
                                  <option *ngFor="let doct of templateList" [value]="doct.templateId">
                                    <ng-container *ngIf="doct.name == ''">
                                        --
                                    </ng-container>
                                    <ng-container *ngIf="doct.name">
                                        {{doct.name}}
                                    </ng-container>
                                  </option>
                            </select>
                            <div class="form-group">
                                <div *ngIf="!templateIdRequired" class="form_alert">
                                    <span class="ft-alert-circle"></span>
                                    Please select template.
                                </div>
                            </div>
                        </div> -->
                        <div class="col-sm bx"><button type="button" class="btn btn-primary mb-2 addnow no-radious"
                                [disabled]="clickedAdd" (click)="fileUpload()">ADD NOW</button>
                            <div *ngIf="clickedAdd" id="loading-bar-spinner" class="spinner">
                                <div class="spinner-icon"></div>
                            </div>
                        </div>


                    </div>
                </fieldset>
            </div>


            <div class="section-documents">
                <label class="titlex">Recipients</label>
                <ng-container
                    *ngIf="fdsRecipientsList.length>0 || (fdsRecipientsOldList && fdsRecipientsOldList.length>0)">
                    <table>
                        <thead>
                            <tr>
                                <th class="th_Recipient advisor-th" style="padding-left: 0px;">Recipient Name</th>
                                <th class="th_Recipient advisor-th">Recipient Email</th>
                                <!-- <th class="th_Recipient advisor-th">Advisor Name</th>
                            <th class="th_Recipient advisor-th" style="min-width: 270px;">Advisor Email</th> -->
                                <th class="th_Recipient"></th>
                                <th class="th_Recipient"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let recipient of fdsRecipientsOldList; let i = index">
                                <tr *ngIf="!recipient.isArchived">
                                    <td>
                                        {{recipient.name}}</td>
                                    <td class="position-relative">
                                        <app-service-agreement-update-widget
                                            (updateRecipientsEmail)="updateRecipientsEmail($event, 1)"
                                            [recipientsId]="recipient.userId" [recipientsEmail]="recipient.email"
                                            [issignRec]="recipient.issignRec">
                                        </app-service-agreement-update-widget>
                                        <div *ngIf="setEmailValidity(recipient['email'])"
                                            class="text-red position-absolute">Please enter a valid email</div>
                                    </td>
                                    <td>
                                        {{recipient.name}}</td>
                                    <td>
                                        <app-sign-app-update-widget
                                            (updateRecipientsSignApp)="updateRecipientsSignApp($event,1)"
                                            [recipientsId]="recipient.userId" [isMobileUser]="recipient.isMobileUser"
                                            [issignRec]="recipient.issignRec">
                                        </app-sign-app-update-widget>
                                    </td>
                                    <td style="padding-left: 10px;">
                                        <span class="rem-btn"
                                            (click)="recipient.issignRec != 'completed' ? deleteRecipient(i, recipient.id, 1) : null">
                                            [ Remove ] </span>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let recipient of fdsRecipientsList; let i = index">
                                <tr>
                                    <td>
                                        {{recipient.name}}</td>
                                    <td class="position-relative">
                                        <app-service-agreement-update-widget
                                            (updateRecipientsEmail)="updateRecipientsEmail($event)"
                                            [recipientsId]="recipient.userId" [recipientsEmail]="recipient.email">
                                        </app-service-agreement-update-widget>
                                        <div *ngIf="setEmailValidity(recipient['email'])"
                                            class="text-red position-absolute">Please enter a valid email</div>
                                    </td>
                                    <td>
                                        {{recipient.name}}</td>
                                    <!-- <td>
                                        <app-advisor-name-update-widget
                                            (updateAdvisorName)="updateAdvisorName($event)"
                                            [recipientsId]="recipient.userId"
                                            [recipientsEmail]="recipient.advisorName">
                                        </app-advisor-name-update-widget>
                                        <div *ngIf="!recipient.advisorName && recipient.advisorEmail" class="form_alert">
                                            <span class="ft-alert-circle"></span>
                                            Please add advisor name.
                                        </div>
                                    </td>
                                    <td>
                                        <app-advisor-email-update-widget
                                            (updateAdvisorEmail)="updateAdvisorEmail($event)"
                                            [recipientsId]="recipient.userId"
                                            [recipientsEmail]="recipient.advisorEmail">
                                        </app-advisor-email-update-widget>
                                        <div *ngIf="!recipient.advisorEmail && recipient.advisorName" class="form_alert">
                                            <span class="ft-alert-circle"></span>
                                            Please add advisor email.
                                        </div>
                                        <div *ngIf="invalidEmail" class="form_alert">
                                            <span class="ft-alert-circle"></span>
                                                Invalid email
                                        </div>
                                    </td> -->
                                    <td>
                                        <app-sign-app-update-widget
                                            (updateRecipientsSignApp)="updateRecipientsSignApp($event,1)"
                                            [recipientsId]="recipient.userId" [isMobileUser]="recipient.isMobileUser">
                                        </app-sign-app-update-widget>
                                    </td>
                                    <td style="padding-left: 10px;">
                                        <span class="rem-btn" (click)="deleteRecipient(i, recipient.id)"> [ Remove ]
                                        </span>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <!-- <table width="100%">
                        
                            <tr>
                                <th  width="(100/6)%">Recipient Name</th>
                                <th  width="(100/6)%">Recipient Email</th>
                                <th  width="(100/6)%">Advisor Name</th>
                                <th  width="(100/6)%">Advisor Email</th>
                                <th  width="(100/6)%"></th>
                                <th  width="(100/6)%"></th>
                            </tr>
                        
                        
                            <ng-container *ngFor="let recipient of fdsRecipientsList; let i = index">
                                <tr>
                                    <td width="(100/6)%"> {{recipient.name}} </td>
                                    <td width="(100/6)%"> 
                                        <app-service-agreement-update-widget
                                            (updateRecipientsEmail)="updateRecipientsEmail($event)"
                                            [recipientsId]="recipient.userId"
                                            [recipientsEmail]="recipient.email">
                                        </app-service-agreement-update-widget>
                                    </td>
                                    <td width="(100/6)%"> 
                                        <app-advisor-name-update-widget
                                            (updateAdvisorName)="updateAdvisorName($event)"
                                            [recipientsId]="recipient.userId"
                                            [recipientsEmail]="recipient.advisorName">
                                        </app-advisor-name-update-widget>
                                    </td>
                                    <td width="(100/6)%"> 
                                        <app-advisor-email-update-widget
                                            (updateAdvisorEmail)="updateAdvisorEmail($event)"
                                            [recipientsId]="recipient.userId"
                                            [recipientsEmail]="recipient.advisorEmail">
                                        </app-advisor-email-update-widget>
                                    </td>
                                    <td width="(100/6)%"> 
                                        <div class="form-check">
                                            <input type="checkbox"  checked="{{recipient.isMobileUser}}" disabled="disabled" class="form-check-input check5" id="isMobileUser">
                                            <label class="form-check-label label5" for="flexCheckDefault"> Notify via app </label>
                                        </div>
                                    </td>
                                    <td width="(100/6)%"> <span class="rem-btn" (click)="deleteRecipient(i)"> [ Remove ] </span> </td>
                                </tr>
                            </ng-container>
                        
                    </table> -->
                </ng-container>


                <div class="section-documents-add">
                    <fieldset class="scheduler-border">
                        <legend class="scheduler-border">Add Recipient </legend>
                        <div class="row">
                            <div class="col-sm bx">
                                <input type="text" id="userIdFirstWay" (ngModelChange)="searchClients($event)"
                                    [(ngModel)]="searchStr" list="dynmicUserIds" placeholder="Search"
                                    [ngModelOptions]="{standalone: true}" class="form-control">
                                <div class="search_b p-1" *ngIf="isShowRecipient && searchText.length > 0">
                                    <ul>
                                        <ng-container *ngIf="searchResults.length == 0">
                                            <a href="javascript:void(0);" class="seach_a">
                                                <li class="search_pad text-center">No result found</li>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf="searchResults.length >0">
                                            <ng-container *ngFor="let client of searchResults; let j = index">
                                                <a (click)="addRecipients(client)" class="seach_a">
                                                    <li class="search_pad">{{client.name}}</li>
                                                </a>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </div>
                                <div class="form-group">
                                    <div *ngIf="!IsvalidRecipient" class="form_alert">
                                        <span class="ft-alert-circle"></span>
                                        Please add at least one recipient.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>


                <div class="form-check" *ngIf="!edit">
                    <input type="checkbox" class="form-check-input check5" id="isDocVisibleToClient"
                        name="isDocVisibleToClient" [(ngModel)]="fdsServiceAgreementAddModel.isDocVisibleToClient"
                        #isDocVisibleToClient="ngModel">
                    <label class="form-check-label label5" for="flexCheckDefault"> Create Envelope </label>
                </div>
            </div>

            <br />

            <div class="modal-footer a-footer">
                <div *ngIf="!Isvalid" class="form_alert">
                    <span class="ft-alert-circle"></span>
                    Please fill in all the required fields correctly.
                </div>
                <div *ngIf="errors" class="form_alert">
                    <span class="ft-alert-circle"></span>
                    {{errors}}
                </div>
                <!-- <button type="button" class="btn popup_delete_b">Delete</button> -->
                <button type="submit" class="btn popup_save_b">{{edit ? 'Update Data' : 'Save'}}</button>
            </div>

        </form>
    </div>
</ng-container>

<ng-container *ngIf="iframeUrl">
    <div class="modal-header a_modal">
        <h4 class="modal-title" id="myModalLabel1">Document</h4>
        <button type="button" (click)="modalRehide(true)" class="close" data-dismiss="modal" aria-label="Close"> <span
                aria-hidden="true">&times;</span> </button>
    </div>

    <div class="modal-body" style="padding: 15px 30px;">
        <iframe width="100%" height="800" [src]="iframeUrl | safeUrl" allowFullScreen="true"></iframe>
    </div>
</ng-container>
<ng-container *ngIf="loading">
    <ngx-ui-loader></ngx-ui-loader>
</ng-container>